<template>
    <div class="inner-container">
        <div class="section-title budget"><i class="icon icon-calculator-coins"></i><span>{{ getCommonText('budget') }}</span></div>
        <div class="section-subtitle"><i class="icon icon-grid"></i><span>{{ $t("app.links.budget_requests") }}<budget-forms-list :curFormSelect="'/form01-123'"/></span>
            <forms-download-reprt
                :progress="progress"
                :isReportUploading="isReportUploading"
                :form="form"
                :guListLen="guListLen"
                :header="header"
                :isParentGuExist="isParentGuExist"
                :isHeadGu="isHeadGu"
                @checkSignatories="checkSignatories"
                @downloadRep="downloadRep"
                @downloadBatchReports="downloadBatchReports"
                @downloadBatchReportsPaid="downloadBatchReportsPaid"
            ></forms-download-reprt>
        </div>
        <div class="filter-container">
            <div class="left-content">
                <budget-header ref="budgetHeader" :form="{spf: form.spf, code: form.code}"
                               @chgData="changeHeader" :yearReq="true" :openDisabled="openDisabled" @setProgress="setProgress"></budget-header>
            </div>
                <div class="right-content">
                <div class="filter-actions filter-actions-flex">
                    <c-budget-forms-copy-data 
                        v-if="!(progress < 100) && variantAttribute && (header.year < header.cur_year + 2)"
                        :budgetForm="budgetForm"
                        :header="header"
                        @keyPress="keyPress"
                    />
                    <b-button :style="{ 'minWidth': '110px' }" variant="primary" @click="addItem" v-if="variantAttribute" :disabled="isLoad"><i class="icon icon-plus-circle"></i> {{ getCommonText('add') }}</b-button>
                    <b-button :style="{ 'minWidth': '110px' }" variant="success" @click="prepareForSave" :disabled="isLoad || !variantAttribute || load">{{ getCommonText('save') }}</b-button>
                </div>
            </div>
        </div>

        <breadcrumbs-filter :header="{...header, formName: this.formName, spfName: this.spfName}" :data-type-filter="dataTypeFilter"
                            :variant-name="variantName" @openFilterByRef="openFilterByRef"/>
        <b-progress
                variant="success"
                v-show="progress < 100 && progress > 0"
                height="10px"
                :value="progress"
                striped
                animated
        ></b-progress>

        <div class="table-container">
            <b-table
                :fields="tableFields"
                :items="budgetForm"
                responsive="true"
                bordered
                head-variant="light"
                sticky-header="true"
                no-border-collapse
            >
                <template #top-row="data">
                    <td class="td-numbering"></td>
                    <td class="td-numbering"></td>
                    <td class="td-numbering table-success">1</td>
                    <td class="td-numbering table-danger">2</td>
                    <td class="td-numbering table-info">3</td>
                    <td class="td-numbering table-primary">4</td>
                    <td class="td-numbering"></td>
                </template>
                <template #head(select)>
                    <div class="text-center">
                        <b-form-checkbox 
                            v-model="selectAll"
                            @change="e => setIsAllDelete(e)"
                            :value="true"
                            :unchecked-value="false"/>
                    </div>
                </template>
                <template #head(more)="data">
                    <div class="text-center">
                        <i 
                            :title="getCommonText('del_selected')"
                            class="icon icon-close table-all-remove"
                            @click="deleteItemWithAttachedFiles(`${selectAll ? getDecText('del_all_recs') : getDecText('del_selected_recs')}`)"/>
                    </div>
                </template>
                <template #head(action)="scope">
                    <b-button @click="openAll()">
                        <i class="icon icon-keyboard icon-rotate-270" v-if="open"></i>
                        <i class="icon icon-keyboard icon-rotate-90" v-if="!open"></i>
                    </b-button>
                </template>

                <template #cell(select)="data">
                    <div class="budget-form-table_select-col">
                        <b-form-checkbox 
                        v-model="data.item.itemToDelete"
                        @input="e => { if (!e) selectAll = false; }"
                        :value="true"
                        :unchecked-value="false" />
                    </div>
                    
                </template>
                <template #cell(action)="data">
                    <b-button @click="showDetails(data.item)">
                        <i class="icon icon-keyboard icon-rotate-270" v-if="data.item._showDetails"></i>
                        <i class="icon icon-keyboard icon-rotate-90" v-if="!data.item._showDetails"></i>
                    </b-button>
                </template>
                <template #cell(number)="data">
                    <div>{{data.item.number}}</div>
                </template>
                <template #cell(name_ru)="data">
                    <multiselect
                            v-if="variantAttribute"
                            v-model="data.item.transferItem"
                            :label="locNameField"
                            track-by="code"
                            placeholder="Pick some"
                            :options="dictTransfersActual"
                            :searchable="true"
                            :allow-empty="false"
                            :show-labels="false"
                            @input="disableSelectedTransfers"
                        >
                    </multiselect>
                    <div v-else class="scroll-container">{{data.item[locNameField]}}</div>
                </template>
                <template #cell(total)="data">
                    <div 
                        class="text-right"
                        :class="{ 'form01-339_total-field': data.item.showValidationError && !data.item.total > 0 }"
                    >
                        <div class="budget-form-val-msg" v-if="data.item.showValidationError && !data.item.total > 0">{{ getCommonText("positive") }}</div>
                        {{ $n(data.item.total) }}
                    </div>
                </template>
                <template #cell(files)="data">
                    <span
                        class="blue pointer underline"
                        @click="openModalRowFilesByRowId(data.item, false)"
                    >({{data.item.files}})</span>
                </template>
                <template #cell(more)="data">
                    <div class="text-center">
                        <i 
                            :title="getCommonText('del_rec')" 
                            class="icon icon-clear table-remove" 
                            v-if="variantAttribute" 
                            @click="deleteItemWithAttachedFiles(`${getCommonText('del_rec')}?`, data.item, data.index)"
                        ></i>
                    </div>
                </template>
                <template #bottom-row="data">
                    <td class="text-right" colspan="4">{{ getCommonText('total') }}</td>
                    <td class="text-right">{{ $n(total) }}</td>
                    <td colspan="2"></td>
                </template>
                <template #row-details="row">
                    <b-card v-if="row.item.code">
                        <b-table
                            :fields="row.item.rowFields"
                            :items="row.item.regionsList"
                            responsive="true"
                            bordered
                            head-variant="light"
                            sticky-header="true"
                            no-border-collapse>
                            <template #cell(row_name_ru)="data">
                                <div class="text-left">{{ data.item[locNameField] }}</div>
                            </template>
                            <template #cell(count)="data">
                                <b-form-input v-if="variantAttribute"
                                              class="text-right"
                                              :value="data.item.count"
                                              @change="v => data.item.count = v"
                                              @keyup.enter.exact="keyup13"
                                              @keypress="keyPress($event, '^[0-9]+$')"
                                              @blur="onBlur(data.item, row.item, 'count', data.item.count, 0)">
                                </b-form-input>
                                <div v-else>{{ data.value }}</div>
                            </template>
                            <template #cell(price)="data">
                                <b-form-input v-if="variantAttribute"
                                              class="text-right"
                                              :value="data.item.price"
                                              @change="v => data.item.price = v"
                                              @keyup.enter.exact="keyup13"
                                              @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                              @blur="onBlur(data.item, row.item, 'price', data.item.price, 2)">
                                </b-form-input>
                                <div v-else>{{ data.value }}</div>
                            </template>
                            <template #cell(rowTotal)="data">
                                <div class="text-right">{{ $n(data.item.rowTotal) }}</div>
                            </template>
                            <template #bottom-row="data">
                                <td class="text-left" colspan="3">{{ getCommonText('total') }}</td>
                                <td class="text-right">{{ $n(row.item.total) }}</td>
                            </template>
                        </b-table>
                    </b-card>
                </template>

            </b-table>
        </div>
        <div class="table-add" v-if="variantAttribute" :disabled="isLoad">
            <span @click="addItem"><i class="icon icon-plus-circle"></i> {{ getCommonText('add') }}</span>
        </div>
        <files-updown :header="header"
                      :variant-attribute="variantAttribute"
                      :load="load"
                      @getFiles="getFiles"
                      @getNewFiles="getNewFiles($event)"
                      @delFile="delFile($event)"
                      ref="fileUpdown"
        ></files-updown>
        <modal-files-management-nodecode
            ref="modalFilesManagement"
            :variant-attribute="variantAttribute"
            @triggDownloadFile="triggDownloadFile"
            @triggDownloadAllFiles="triggDownloadAllFiles"
            @fileUpload="fileUpload"
            @toggleIsAdd="toggleIsAdd($event)"
            />
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import BudgetHeader from '@/modules/budget-request/budget-header-new.vue';
import FilesUpdown from '@/modules/budget-request/FilesUpdown';
import BudgetFormsList from '@/modules/budget-request/components/budget-forms-list.vue';
import BreadcrumbsFilter from '@/modules/budget-request/components/breadcrumbs-filter.vue';
import store from '../../../services/store';
import ModalFilesManagementNodecode from '@/modules/budget-request/components/modal-files-management-nodecode.vue';
import FormsHandlerMixin1 from "../mixins/forms-handler-mixin-1";
import CBudgetFormsCopyData from '../components/budget-forms-copy-data.vue';
import FormsDownloadReprt from "../components/forms-download-reprt.vue";
import _ from 'lodash';

export default {
    name: 'Form01-339',
    components: { BudgetHeader, FilesUpdown, BudgetFormsList, FormsDownloadReprt, BreadcrumbsFilter, ModalFilesManagementNodecode, CBudgetFormsCopyData },
    mixins: [FormsHandlerMixin1],
    data() {
        return {
            form: {
                code: '01-339',
                name_kk: '',
                name_ru: 'Расчет затрат на прочие текущие трансферты другим уровням государственного управления',
                spf: {
                    name: '01-339-Текущие трансферты другим уровням государственного управления',
                    spf: '339'
                }
            },
            dataTypeFilter: null,
            variantAttribute: null,
            variantName: null,
            budgetForm: [],
            header: null,
            files: null,
            load: true,
            openDisabled: false,
            mrp: 0,
            obl: null,
            region: null,
            currentRegions: [],
            listExistingReg: [],
            dictTransfers: [],
            dictTransfersActual: [],
            isLoad: false,
            open: false,
            row_files: [],
            isAdd: false,
            rowId: false,
            newRowStartId: -1,
            defaultfilesList: [],
        };
    },
    async mounted() {
        await this.getObl();
        await this.loadTransfers();
        await this.loadRegions();
    },
    methods: {
        addItem() {
            const newIem = {
                code: '',
                name_ru: '',
                name_kk: '',
            };
            const newFormRec = this.creatingFormRecord(newIem)
            this.budgetForm.push(newFormRec);
            this.setFormRecIndex();
        },
        openModalRowFilesByRowId(item, isAdd) {
            const rowId = item.id;
            this.row_files = this.budgetForm.find(b => b.id === rowId)['row_files'];
            this.$refs.modalFilesManagement.showModal(item);
            this.rowId = rowId;
            this.isAdd = isAdd;
        },
        toggleIsAdd(return_object) {
            const curBudgetForm = this.budgetForm.find(b => b.id === this.rowId)
            curBudgetForm['row_files'] = return_object['row_files']
            curBudgetForm['files'] = return_object['num_attach_files']
            this.isAdd = return_object['isAdd'];
        },
        fileUpload(rowId) {
            this.$refs.fileUpdown.openModalFileUpload();
            this.$refs.fileUpdown.setRowId(rowId);
        },
        getNewFiles(files) {
            this.budgetForm.forEach(item => {
                let num_attach_files = 0;
                item['row_files'] = [...item['row_files'], ...files];
                item['row_files'].forEach((file) => {
                    if (file.row_id === item['id']) {
                        num_attach_files += 1;
                    }
                });
                item['files'] = num_attach_files;
            });
            this.$refs.modalFilesManagement.addNewFiles(files);
        },
        delFile(fileId) {
            this.budgetForm.forEach(item => {
                item['row_files'].forEach((file, index) => {
                    if (file.file_id === fileId) {
                        item['row_files'].splice(index, 1);
                    }
                });
                item.files = item['row_files'].filter(i => i.row_id !== null).length;
            });
        },

        async getObl() {
            try {
                await fetch('/api-py/get-budget-obl/' + store.state._instanceCode)
                    .then(response => response.json())
                    .then(json => {
                        this.obl = json.obl;
                        this.region = json.region;
                    });
            } catch (error) {
                this.makeToast('danger', `${this.getErrText('bad_request')} getObl()`, error.toString());
            }
        },

        async loadRegions() {
            this.currentRegions = [];
            try {
                const response = await fetch('/api-py/get-regions-by-obl/' + this.obl);
                const items = await response.json();
                this.currentRegions = items;
            } catch (error) {
                this.makeToast('danger', `${this.getErrText('bad_request')} регионов`, error.toString());
            }
        }, // справочник регионов

        loadRegionsByTransfer(regionsData, dataItem) {
            const items = JSON.parse(JSON.stringify(this.currentRegions));
            const result = [];
            const currRegionsArr = regionsData ? regionsData[dataItem.id] : null;
            items.forEach(row => {
                const currRegion = currRegionsArr ? currRegionsArr.find(itm => itm.code === row.code) : null;
                const el = {};
                this.$set(el, 'id', currRegion ? currRegion.id : 0);
                this.$set(el, 'code', row.code);
                this.$set(el, 'name_ru', row.name_ru);
                this.$set(el, 'name_kk', row.name_kk);
                this.$set(el, 'count', currRegion ? currRegion.count : 0);
                this.$set(el, 'price', currRegion ? currRegion.price : 0);
                this.$set(el, 'changed', false);
                Object.defineProperty(el, 'rowTotal', {
                    get: function () {
                        const sum = parseFloat(el.count) * parseFloat(el.price) / 1000;
                        return parseFloat((Math.round(sum * 1000) / 1000).toFixed(3));
                    }
                });
                const elP = new Proxy(el, {
                    set(target, prop, value) {
                        if (prop in target) {
                            if (prop === 'count' || prop === 'price') {
                                target.changed = true;
                            }
                            target[prop] = value;
                            return true;
                        } else {
                            throw new Erro(`No ${prop} field in target`)
                        }
                    }
                })
                result.push(elP);
            });
            result.sort((a, b) => a.name - b.name);
            return result;
        }, // справочник регионов

        async loadTransfers() {
            try {
                const response = await fetch('/api-py/dict_transfer');
                if (response.status === 200) {
                    const trList = await response.json();
                    this.setDictTransfers(trList);
                }
            } catch (error) {
                this.makeToast('danger', `${this.getErrText('bad_request')} loadTransfers()`, error.toString());
            }
        },

        setDictTransfers(trList) {
            const dtTrs = [];
            if (!trList.length) return dtTrs;
            const addedCodes = {};
            
            const sortedTrList = _.orderBy(trList, ['code', 'end_date'], ['asc', 'desc']);
            sortedTrList.forEach(tr => {
                if (!addedCodes[tr.code]) {
                    dtTrs.push({
                        code: tr.code,
                        name_ru: tr.name_ru,
                        name_kk: tr.name_kk,
                        $isDisabled: false,
                        expired: tr.expired,
                    });
                    // 
                    addedCodes[tr.code] = true;
                };
            });
            
            this.dictTransfers = dtTrs;
            this.dictTransfersActual = dtTrs.filter(itm => (!itm.expired));
        },

        disableSelectedTransfers() {
            if (!this.budgetForm.length) return;
            const selectedCodes = {};
            this.budgetForm.forEach(item => selectedCodes[item.code] = true);
            this.dictTransfersActual.forEach(item => item.$isDisabled = !!selectedCodes[item.code]);
        },

        resetData(item, index) {
            if (!item || !item.regionsList || item.regionsList.length === 0) return;
            item.regionsList.forEach((reg) => {
                if (reg.count !== 0) {
                    this.$set(reg, 'count', 0);
                }
                if (reg.price !== 0) {
                    this.$set(reg, 'price', 0);
                }
                if (reg.rowTotal !== 0) {
                    this.$set(reg, 'rowTotal', 0);
                }
                this.$set(reg, 'changed', false);
                this.$set(reg, 'id', 0);
            });
            this.$set(item, 'files', 0)

        }, // очистка данных во вложенных строках

        spliceTableRecord(item) {
            const deletingRowIdx = this.budgetForm.findIndex(itm => itm.id === item.id);
            if (deletingRowIdx !== -1) this.budgetForm.splice(deletingRowIdx, 1);
        },

        getFiles(data) {
            this.files = data;
        },

        keyPress: function (event, pattern) {
            // const regex = new RegExp('^[0-9]+$');
            // const regex = new RegExp('^-?\\d*\\d{0,9}$');
            // const regex = new RegExp('^-?\\d*\\.?\\d{0,9}$');
            const regex = new RegExp(pattern);
            const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        }, // вводит по заданному паттерну

        keyup13: function (event) {
            event.preventDefault();
            // Isolate the node that we're after
            const currentNode = event.target;
            // find all tab-able elements
            const allElements = document.querySelectorAll('input'); // area, object, select, [contenteditable]
            // Find the current tab index.
            const currentIndex = [...allElements].findIndex(el => currentNode.isEqualNode(el));
            // select/focus the following element
            const targetIndex = (currentIndex + 1) % allElements.length;
            if (targetIndex < allElements.length) {
                allElements[targetIndex].select();
            }
        }, // enter работает как tab

        openAll() {
            this.open = !this.open;
            for (const row of this.budgetForm) {
                row._showDetails = !this.open;
                this.showDetails(row);
            }
        },

        showDetails(row) {
            row._showDetails = !row._showDetails;
        },

        async loadDatas() {
            const that = this;
            that.load = true;
            this.defaultfilesList.splice(0);

            let values = [];
            const existingIdList = []
            try {
                const response = await fetch('/api-py/get-budget-request-form-with-row-attach-files/' + JSON.stringify(that.header));
                if (response.status == 200) {
                    values = await response.json();
                    if (values.length) {
                        existingIdList.push(values[0].id)
                        values.sort((a, b) => {
                            existingIdList.push(a.id)
                            if (a.code < b.code) { return -1; }
                            if (a.code > b.code) { return 1; }
                            return 0;
                        });
                    }
                } else {
                    this.budgetForm.splice(0);
                    this.makeToast('danger', `${this.getErrText('bad_request')} loadDatas()`, `Error code: ${response.status}`);
                    return;
                }
            } catch (error) {
                this.budgetForm.splice(0);
                that.makeToast('danger', `${this.getErrText('bad_request')} loadDatas()`, error.toString());
                return;
            }

            const existingIdObj = await that.loadExistingRegions(existingIdList);

            const prepareFormData = []
            for (const item of this.dictTransfers) {
                const itemExist = values.find(val => val.code === item.code);
                if (!itemExist) continue;

                const isLoadFilesForEmptyRow = !itemExist && this.defaultfilesList.length === 0
                if (isLoadFilesForEmptyRow) {
                    this.defaultfilesList = await this.loadAllFiles(-1);
                }
                const dataItem = this.creatingFormRecord(item, itemExist, existingIdObj)
                prepareFormData.push(dataItem);
            }
            this.budgetForm = prepareFormData; 
            this.setFormRecIndex();
            that.load = false;
        },

        creatingFormRecord(item, itemExist = null, existingIdObj = null) {
            const dataItem = {
                id: itemExist ? itemExist.id : this.newRowStartId,
                // code: item.code,
                transferItem: item,
                // name_ru: item.name_ru,
                changed: false,
                // name_kk: item.name_kk,
                
            };
            this.newRowStartId--;

            this.$set(dataItem, 'files', itemExist ? itemExist.files : 0);
            this.$set(dataItem, 'row_files', itemExist ? itemExist.row_files : []);

            this.$set(dataItem, 'regionsList', this.loadRegionsByTransfer(existingIdObj, dataItem));
            
            this.$set(dataItem, '_showDetails', false);
            Object.defineProperty(dataItem, 'rowFields', {
                get: () => {
                    const rowFields = [
                        {
                            key: 'row_name_ru',
                            label: this.getCommonText('name')
                        },
                        {
                            key: 'count',
                            label: this.getCommonText('count')
                        },
                        {
                            key: 'price',
                            label: this.getFormText('price_per_unit')
                        },
                        {
                            key: 'rowTotal',
                            label: this.getFormText('total_cost')
                        }
                    ];
                    if (this.lang) return rowFields;
                    return rowFields;
                }
            });
            Object.defineProperty(dataItem, 'code', {
                get: function () {
                    return dataItem.transferItem.code;
                }
            });
            Object.defineProperty(dataItem, 'name_ru', {
                get: function () {
                    return dataItem.transferItem.name_ru;
                }
            });
            Object.defineProperty(dataItem, 'name_kk', {
                get: function () {
                    return dataItem.transferItem.name_kk;
                }
            });
            Object.defineProperty(dataItem, 'total', {
                get: function () {
                    let total = 0;
                    if (this.regionsList) {
                        this.regionsList.forEach(el => {
                            total += el.rowTotal;
                        });
                    }
                    return parseFloat((Math.round(total * 1000) / 1000).toFixed(3));
                }
            });
            return dataItem;
        },
        
        async loadAllFiles(id) {
            let emptyRowFiles = [];
            try {
                const response = await fetch(`/api-py/get-new-row-files-form/${id}/${JSON.stringify(this.header)}`);
                const result = await response.json();
                if (result.length) emptyRowFiles = result
                else emptyRowFiles = ['no files']
            } catch (error) {
                this.makeToast('danger', `${this.getErrText('bad_request')} itemUpdate`, error.toString());
            }
            return emptyRowFiles;
        },

        async loadExistingRegions(existingIdList) {
            if (existingIdList.length === 0) return;
            let listExistingReg = [];
            try {
                const response = await fetch('/api-py/get-budget-request-form-01-339-regions/' + this.header.mode, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(existingIdList)
                });
                listExistingReg = await response.json();
                listExistingReg.forEach(item => {
                    Object.defineProperty(item, 'rowTotal', {
                        get: function () {
                            const sum = parseFloat(item.count) * parseFloat(item.price) / 1000;
                            return parseFloat((Math.round(sum * 1000) / 1000).toFixed(3));
                        }
                    });
                });
                listExistingReg.sort((a, b) => {
                    if (a.name_ru < b.name_ru) { return -1; }
                    if (a.name_ru > b.name_ru) { return 1; }
                    return 0;
                });
                if (listExistingReg.length === 0) {
                    return;
                }
                const objExistingReg = {};
                listExistingReg.forEach(itm => {
                    if (objExistingReg[itm.parent_id]) {
                        objExistingReg[itm.parent_id] = [...objExistingReg[itm.parent_id], itm];
                    } else {
                        objExistingReg[itm.parent_id] = [itm];
                    };
                    
                })
                return objExistingReg;
                
            } catch (error) {
                this.makeToast('danger', `${this.getErrText('bad_request')} loadExistingRegions()`, error.toString());
            }
        },

        onBlur(item, row, fieldName, value, rank) {
            this.inputFixedVldtn(item, fieldName, value, rank);
            this.$nextTick(() => {
                if (row.showValidationError && row.total > 0) {
                    delete row.showValidationError;
                }
            });    
        },
    
        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        }, // сообщение

        prepareForSave() {
            const values = [];
            let error = false;
            for (const row of this.budgetForm) {
                if (!row.code) continue;
                const item = Object.assign({}, this.header);
                this.$set(item, 'id', row.id);
                this.$set(item, 'code', row.code);
                this.$set(item, 'name_ru', row.name_ru);
                this.$set(item, 'name_kk', row.name_kk)
                this.$set(item, 'total', parseFloat(row.total));
                this.$set(item, 'row_files', row.row_files);
                this.$set(item, 'childRegionsList', row.regionsList);
                if (!row.total) {
                    this.$set(row, 'showValidationError', true);
                    error = true;
                }
                values.push(item);
            }
            if (error) {
                this.onFieldsValidationFailed();
                return;
            }
            if (values.length > 0) {
                this.save(values);
            } else {
                this.makeToast('warning', this.getErrText('msg'), this.getErrText('not_saved'));
            }
        },

        async save(values) {
            this.isLoad = true;
            try {
                this.$set(this.header, 'value', this.total);
                const response = await fetch('/api-py/save-brform' + this.form.code + '/' + JSON.stringify(this.header), {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(values)
                });
                const result = await response.json();
                if (response.status === 200) {
                    this.makeToast('success', this.getErrText('msg'), this.getErrText('saved'));
                    this.deletingAgreementTotalResultHandler(result);
                    if (!this.files || this.files.length === 0) {
                        this.makeToast('warning', this.getErrText('warning'), this.getErrText('no_docs'));
                    }
                    await this.loadDatas();
                } else {
                    await this.loadDatas();
                    throw ' ';
                }
            } catch (e) {
                this.makeToast('danger', this.getErrText('warning'), e.toString());
            } finally {
                this.isLoad = false;
            }
        }, // сохранение данных

        setFormRecIndex() {
            if (!this.budgetForm.length) return;
            this.budgetForm.forEach((item, idx) => item.number = idx + 1);
        },
    },
    watch: {
        budgetForm: function() {
             this.disableSelectedTransfers();
        },
    },
    computed: {
        total() {
            let sum = 0;
            for (const row of this.budgetForm) {
                sum += parseFloat(row.total);
            }
            return Math.ceil(sum);
        },

        locNameField() {
            return `name_${this.lng}`
        },

        getFormText() {
            return this.setFormText('form_01_339.');
        },

        tableFields() {
            if (this.$i18n.locale) {
                return [
                    {
                        key: 'select',
                        label: ' '
                    },
                    {
                        key: 'action',
                        label: ' '
                    },
                    {
                        key: 'number',
                        label: this.getCommonText('sn')
                    },
                    {
                        key: 'name_ru',
                        label: this.getFormText('namelg')
                    },
                    {
                        key: 'total',
                        label: this.getCommonText('sum_tt')
                    },                {
                        key: 'files',
                        label: this.getCommonText('files')
                    },
                    {
                        key: 'more',
                        label: ''
                    }
                ];
            };
            return [];
        },
    }
};
</script>
<style scoped>
    .filter-actions-flex {
        display: flex;
    }
    .budget-form-table_select-col {
        display: flex;
        justify-content: center;
    }
    .form01-339_total-field {
        display: flex;
        justify-content: space-between;
    }
    .form01-339_total-field div {
        padding: 5px 0 0 0;
    }
    .budget-form-val-msg {
        font-size: 0.5rem;
        font-style: italic;
        color: red;
    }
</style>